import React from "react"
import styled from "styled-components"
import {colors} from "../variables";
import Img from "gatsby-image"
import {getImage, GatsbyImage} from "gatsby-plugin-image"
import {Fade} from "react-reveal";
import {sortModulesAndAssets} from "../../plugins/berry-plugin/util/helpers"

const PortfolioItem = styled(({className, title, content, img, alt, delay}) => {
    const image = getImage(img)
    return (
        <Fade bottom duration={500} delay={delay}>
        <div className={className}>
            <div className="topImage">
                {/*<Img fluid={img} />*/}
                <GatsbyImage image={image} alt={alt} />
            </div>
            <div className="content">
                <div className="text">
                    {/*<ul>*/}
                    {/*    <li>Poslovna analiza</li>*/}
                    {/*    <li>Strategijski menadžment</li>*/}
                    {/*    <li>Operativni menadžment</li>*/}
                    {/*    <li>Organizacione promjene</li>*/}
                    {/*    <li>Implementacija poslovnog softvera</li>*/}
                    {/*    <li>Izrada i implementacija biznis planova</li>*/}
                    {/*</ul>*/}
                    <h3>{title}</h3>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                </div>
            </div>
        </div>
        </Fade>
    )
})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height:400px;
    width:100%;
    align-self: flex-start;
    margin-bottom: 25px;
    border-bottom: 4px solid ${colors.portfolioBorder};
    @media (min-width: 960px) {
      width:32%;
      min-height:530px;
      margin-right:2%;
      &:nth-child(3n +3) {
        margin-right:0;
      }
    }
  @media (min-width: 1200px) {
    width:24%;
    margin-right:1.3333333%;
    &:nth-child(3n +3) {
      margin-right:1.3333333%;
    }
    &:nth-child(4n +4) {
      margin-right:0;
    }
  }
    .topImage {
      width:100%;
    }
   h3 {
     padding-bottom: 2px;
     margin-bottom: 10px;
     border-bottom: 1px solid ${colors.lightGreyText};
   }
    .content {
      padding:15px 0 10px 0;
      width:100%;
      ul {
        list-style-type: none;
        padding:0;
        margin:0;
        li {
          margin-bottom:10px;
          border-bottom: 1px dashed ${colors.portfolioBorder};
          padding-bottom:2px;
        }
      }
    }
`

const PortfolioList = styled(({className, portfolios, lang}) => {
    // const locales = partners.map( p => {
    //     return
    // })
    // console.log("PORTFOLIOS: ", portfolios)
    return (
        <div className={className}>
            { sortModulesAndAssets(portfolios).map((p, index) => {
                return <PortfolioItem delay={100*index} alt={p.asset.locales.find(l => l.iso === lang).alt} key={p.uid} title={p.locales.find(l => l.iso === lang).title} content={p.locales.find(l => l.iso === lang).content} img={p.imageFile} />


            })}
            {/*<ServiceItem />*/}
            {/*<ServiceItem />*/}
            {/*<ServiceItem />*/}
        </div>
    )
})`
  display:flex;
  justify-content: flex-start;
  
  align-items: center;
  width:100%;
  margin-top:60px;
  flex-wrap:wrap;
`

export default PortfolioList
