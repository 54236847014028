
import React from 'react';
import {graphql} from "gatsby";
import styled from "styled-components"
import Layout from "../components/layout";
import Seo from "../components/seo";
import SectionTitle from "../components/SectionTitle";
import PortfolioList from "../components/PortfolioList";
import {colors} from "../variables";

const PortfolioSection = styled.section`
  max-width:1200px;
  //margin:80px auto auto;
  margin:0 auto;
  width:100%;
  padding: 60px 25px;

  @media (min-width: 768px) {
    padding: 120px 25px;
  }
  @media (min-width: 960px) {
    margin-top:0;
  }
`

function Portfolio({data, pageContext, location}) {
    const pageData = pageContext.pageData
    const metaInfo = pageContext.metaInfo
    return (
        <Layout department={data.department} lang={pageContext.lang} location={location.pathname} loclales={pageContext.locales} slug={pageContext.slug} otherLang={pageContext.otherLang} defLang={pageContext.defLang}>
            <Seo
                title={metaInfo.metaTitle}
                description={metaInfo.metaDescription}
            />
            <div className="portfolio-wrapper" style={{background: colors.whiteShade}}>
                <PortfolioSection>
                    <SectionTitle style={{color: colors.orange}}>{pageData.titlesection.title[pageContext.lang]}</SectionTitle>
                    <div className="portfolios">
                        <PortfolioList portfolios={data.allPortfolio.nodes} lang={pageContext.lang} />
                    </div>
                </PortfolioSection>
            </div>
        </Layout>
    );
}

export const query = graphql`
{
 department(name: {eq: "main"}) {
        contacts {
          type
          value
        }
        location {
          address1
          city
        }
      }
      allPortfolio {
        nodes {
          uid
          portfolioUrl
          position
          locales {
            value
            title
            metaTitle
            metaKeywords
            metaDescription
            iso
            description
            content
          }
          asset {
            locales {
                iso
                alt
            }
          }
          imageFile {
            childImageSharp {
                gatsbyImageData(
                    width: 600
                    aspectRatio: 1
                    transformOptions: {cropFocus: CENTER}
                    quality: 90
                    placeholder: BLURRED
                  )
            }
          }
        }
      }
    }
`

export default Portfolio;
